import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import React from "react";

const StudentJobsPage = () => {
  return (
    <Page>
      <div className="relative">
        <span
          className="absolute opacity-40 inset-0 w-full h-full"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.42) 5.45%, rgba(0, 0, 0, 0) 25.31%, rgba(0, 0, 0, 0) 70.71%, rgba(0, 0, 0, 0.4) 95.6%)",
          }}
        ></span>
        <MotionImage
          className="w-full object-cover"
          style={{ maxHeight: 400, aspectRatio: 1400 / 761 }}
          src="/hero-image.jpg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
      </div>
      <section className="container mx-auto">
        <div className="mx-auto mt-24">
          <iframe
            className="mx-auto max-w-full"
            src="https://docs.google.com/forms/d/e/1FAIpQLScHOv2Cc5BCjtY4G6Rqyau8b5Y7CdZugWsLgQX8WttvT34iGQ/viewform?embedded=true"
            width="640"
            height="2697"
            frameBorder="0"
            marginHeight={0}
          >
            Loading…
          </iframe>
        </div>
      </section>
    </Page>
  );
};

export default StudentJobsPage;
